import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './panels.module.scss';
import Uploader from 'atoms/Uploader/Uploader';
import { uploaderType } from 'atoms/Uploader/Uploader';

const DigitalLabel = ({
  device: {
    currentLabelFileName,
    pendingLabelFileName,
    currentLabelUrl,
    pendingLabelUrl,
    uploader,
  },
  onUpload,
}) => {
  const [uploadError, setUploadError] = useState();

  const handleError = error => {
    setUploadError(error);
  };

  return (
    <div
      className={`${styles.tabPanel} general-tab pb-2 row justify-content-between mx-0`}
    >
      {currentLabelUrl && (
        <div
          className={`${styles.customCol} ${
            currentLabelUrl && pendingLabelUrl ? styles.twoPictures : ''
          }`}
        >
          <p className={`mb-1 text-center ${styles.labelName}`}>
            Existing Label: <span>{currentLabelFileName}</span>
          </p>
          <div className={styles.existingLabelWrapper}>
            <img
              className={styles.lastLabelImage}
              src={currentLabelUrl}
              alt={currentLabelFileName}
            />
          </div>
        </div>
      )}
      {pendingLabelUrl && (
        <div
          className={`${styles.customCol} ${
            currentLabelUrl && pendingLabelUrl ? styles.twoPictures : ''
          }`}
        >
          <p className={`mb-1 text-center ${styles.labelName}`}>
            Pending Label: <span>{pendingLabelFileName}</span>
          </p>
          <div className={styles.pendingLabelWrapper}>
            <img
              className={styles.pendingLabelImage}
              src={pendingLabelUrl}
              alt={pendingLabelFileName}
            />
            <div className={styles.pendingLabelText}>
              <i className="fal fa-clock"></i>
              <p className="m-0 px-1">
                Label will update on the device shortly.
              </p>
            </div>
          </div>
        </div>
      )}
      <div className={styles.uploaderCol}>
        <p className="mb-1">
          <span>Upload new label:</span>
        </p>
        <Uploader
          uploader={uploader}
          onFinish={onUpload}
          onError={handleError}
        />
        <p className={styles.error}>{uploadError}</p>
      </div>
      <small className={styles.digitalLabelTip}>
        <b>Max 2mb:</b> jpg, jpeg, png, gif, bmp, pdf
      </small>
    </div>
  );
};

DigitalLabel.propTypes = {
  device: PropTypes.shape({
    currentLabelFileName: PropTypes.string,
    pendingLabelFileName: PropTypes.string,
    currentLabelUrl: PropTypes.string,
    pendingLabelUrl: PropTypes.string,
    uploader: uploaderType,
  }),
  onUpload: PropTypes.func,
};

export default DigitalLabel;
