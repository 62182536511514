import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useExpanded, useSortBy, usePagination } from 'react-table';

import TableCore from '../TableCore/TableCore';

import ExpandRowButton from './ExpandRowButton';
import ExpandableRowComponent from './ExpandableRowComponent';
import styles from './ExpandableTable.module.scss';

const ExpandableTable = ({
  columns,
  data,
  renderRowSubComponent,
  className,
  paginationClassName,
  getRowsData,
  emptyComponent,
  showMap,
  singleExpand,
  ...rest
}) => {
  const stateReducer = (newState, action, prevState) => {
    if (action.type == 'toggleExpanded' && singleExpand) {
      const state = { ...newState };

      for (const rowId in prevState.expanded) {
        delete state.expanded[rowId];
      }

      return state;
    } else {
      return newState;
    }
  };

  const tableProps = useTable(
    {
      columns,
      data,
      stateReducer,
      initialState: {
        pageSize: 50,
      },
    },
    useExpanded,
    useSortBy,
    usePagination,
  );

  const {
    flatColumns,
    page,
    state: { expanded },
  } = tableProps;

  useEffect(() => {
    getRowsData(page);
  }, [page]);

  return (
    <TableCore
      {...{
        tableProps,
        rowProps: { renderRowSubComponent, flatColumns },
        rowComponent: ExpandableRowComponent,
        className: `${styles.expandableTable} ${className}`,
        paginationClassName,
        emptyComponent,
        showMap,
        ...rest,
      }}
    />
  );
};

ExpandableTable.ExpandRowButton = props => <ExpandRowButton {...props} />;
ExpandableTable.ExpandRowButton.displayName = 'ExpandRowButton';

ExpandableTable.propTypes = {
  className: PropTypes.string,
  paginationClassName: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  renderRowSubComponent: PropTypes.func,
  getRowsData: PropTypes.func,
  emptyComponent: PropTypes.element,
  showMap: PropTypes.func,
  singleExpand: PropTypes.bool,
};

ExpandableTable.defaultProps = {
  columns: [],
  data: [],
  getRowsData: () => {},
  paginationClassName: '',
  singleExpand: false,
};

export default ExpandableTable;
